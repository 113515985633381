<template>
    <div class="recipe-item">
        <router-link :to="{ name: 'RecipeDetails', params:{recipe_slug: recipe.slug}}" @click.native="setDataLayer('details_click', 'recipe_click', recipe.name)">
            <img  v-if="recipe.image && itemSrc(recipe, 'w1080h970') != 'no-image' && itemSrcset(recipe) != 'no-image'"
                sizes="(max-width: 767px) 100vw, 50vw" 
                :srcset="itemSrcset(recipe)"
                :src="src_url + '/' + itemSrc(recipe, 'w1080h970')"                 
                class="w-100"
            />
            <!-- noimage -->
            <img v-else src="@/assets/images/noimages/noimage-recept.jpg" class="w-100"/>
            <div class="data">
                <div class="name">{{recipe.name}}</div>
                <div class="tags-wrap" v-if="recipe.recipe_tag">
                    <!-- has any tags -->
                    <span class="making-time">
                        <img src='@/assets/images/icons/clock.svg' />
                        {{recipe.making_time}}
                    </span>
                    <span class="dish" v-if="recipe.dish != ''">
                        <img src='@/assets/images/icons/user.svg' />
                        {{recipe.dish}}
                    </span>                       
                    <span v-for="tag in recipe.recipe_tag" :key='tag.id'>
                        <img v-if=" tag.image" :src="src_url + tag.image.path + '/' + tag.image.file_name"   />
                        {{tag.name}}
                    </span>                             
                </div>
                <!-- no custom tags -->
                <div class="tags-wrap" v-else>   
                    <!-- no other tags                  -->
                    <span class="making-time">
                        <img src='@/assets/images/icons/clock.svg' />
                        {{recipe.making_time}}
                    </span>
                    <span class="dish" v-if="recipe.dish != ''">
                        <img src='@/assets/images/icons/user.svg' />
                        {{recipe.dish}}
                    </span>                               
                </div>
            </div>     
        </router-link>   
    </div>
</template>

<script>
export default {
    props:['recipe'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    }   
}
</script>