<template>
    <div class="recipes page">
        <div class="half-left-dc half-left-dc--top"></div>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <h1>Receptek</h1>
                </b-col>                
            </b-row>
            <b-row class="recipes__filter-search">
                <b-col cols="12" md="7" lg="8" class="recipes__filter-wrap">   
                    <div class="recipes__filter-wrap-inner">
                        <ul class="recipes__filter" v-if="recipeCategories.length > 0">                        
                            <li v-for='(filter, index) in recipeCategories' :key="filter.id" >
                                <router-link :to="$route.path +'#' + url_slug(filter.name)"
                                    :class="{ 'router-link-exact-active router-link-active active' : needFirstborder() && index == 0}"
                                    class="filter_button"
                                    @click.native="resetFilter()"
                                    :id="'filter_' + index"
                                >
                                    <img  v-if="filter.image"                                                                                 
                                        :src="src_url + filter.image.path + '/' + filter.image.file_name"  
                                        class="normal-img"                                      
                                    />
                                    <img  v-if="filter.image_active"                                                                                 
                                        :src="src_url + filter.image_active.path + '/' + filter.image_active.file_name"    
                                        class="active-img"                                    
                                    />
                                    {{filter.name}}
                                </router-link>
                            </li>
                        </ul>  
                    </div>                                                                
                </b-col>
                <b-col cols="12" md="5" lg="4">
                    <div class="recipes__search">
                        <input type="text" id="recipe-search" ref="search" name="recipe-search" v-model="search_text"  />
                        <button type="button" class="btn--search" v-if="search_text == ''"></button>
                        <button type="button" class="btn--search-del" v-else @click="search_text = ''"></button>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="currentRecipeList.length > 0">
                <b-col cols="12" md="6" lg="4" v-for="(recipe, rindex) in currentRecipeList" :key="recipe.id" class="recipe-item-wrap">
                    <RecipeItem :recipe="recipe" v-if='rindex < currentEnd'/>
                </b-col>
                <b-col cols="12" v-if='currentRecipeList.length>recipeListStep && currentEnd < currentRecipeList.length' class="btn-wrap">
                    <button type="button" class="btn btn--more" @click="showMore()">Mutass többet!</button>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col cols="12">
                    <div class="search__no-result">
                        <div class="design-cirle"></div>
                        <img src="@/assets/images/icons/search-big.svg" class="search-icon-big" />
                        <p class="title">Nincs találat...</p>
                        <img src="@/assets/images/design/dot-line-no-result.svg" />
                        <p>Sajnáljuk, a keresett kulcsszóra nincs találat.<br/> Kérjük, próbálkozz mással.</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>        
        <div class="producst-wrap">
            <div class="half-left-dc half-left-dc--bottom"></div>            
            <ProductsRow />            
        </div>            
        <Socialnetwork  />        
    </div>
</template>

<script>
import RecipeItem from '@/components/pages/RecipeItem.vue'
import apiH from '@/mixins/apiHandlerMI.js'
import ProductsRow from '@/components/layout/ProductsRow'
import Socialnetwork from '@/components/layout/Socialnetwork'

export default {
    data(){
        return{
            recipeList: [],
            currentRecipeList: [],
            recipeLimit: 12,
            currentEnd: 12,
            recipeListStep:12,
            recipeCategories:[],
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME,
            current_category_filter: -1,
            search_text: '',
            currentHash: '',
            typingTimer: null            
        }
    },    
    mixins: [apiH],
    components:{
        RecipeItem,
        ProductsRow,
        Socialnetwork
    },
    created(){
        this.loadRecipes()
        this.loadRecipeCategories()
    },
    mounted(){
            this.setMetaTags({
                title: 'Kunsági Éden - Receptek', 
                desc: '', 
                keywords: '', 
                ogType: 'website', 
                ogTitle: 'Kunsági Éden - Receptek',
                ogImage: null, 
                ogImageWidth: null, 
                ogImageHeight: null, 
                ogDesc:''
            })
    },
    watch:{
        '$route'(){            
            this.getFilterFromHash();            
        },
        search_text: function(){            
            let _this = this                  

            this.$refs.search.addEventListener('keyup', function () {                
                clearTimeout(this.typingTimer);
                this.typingTimer = setTimeout( function(){                    
                    _this.search()
                }, 1000);
            });
            
            this.$refs.search.addEventListener('keydown', function () {                
                clearTimeout(this.typingTimer);
            });
        }       
    },
    methods:{
        loadRecipes(){
            var _this = this;                       
            _this.get('recipe',
                {
                    params: {      
                        relations:1,                                       
                        where: [['where','status', '1']],
                        order_by:[
                            ['created_at','desc'],
                            ['sort_order','asc']                
                        ],  
                    }
                },
                function(resp){                                        
                    _this.recipeList = resp.data.recipe

                    //fill search helper
                    for (var i in _this.recipeList){
                        _this.recipeList[i].search_helper = _this.recipeList[i].name.toLowerCase();
                        _this.recipeList[i].search_helper += " " + _this.recipeList[i].description.toLowerCase();
                        _this.recipeList[i].search_helper += " " + _this.recipeList[i].short_description.toLowerCase();
                    }

                    _this.currentRecipeList = _this.recipeList
                }, function(err){
                    console.log(err);
                    _this.recipeList = []
                }
            )
        },
        loadRecipeCategories(){
            var _this = this;            

            _this.get('recipe_category',
                {
                    params: {                                              
                        where: [['where','status', '1']]
                    }
                },
                function(resp){                             
                    _this.recipeCategories = resp.data.recipe_category
                    _this.recipeCategories.unshift({
                        name:"Összes"
                    })
                }, function(err){
                    console.log(err);
                    _this.recipeCategories = []
                }
            )
        },
        showMore(){
            this.currentEnd += this.recipeListStep

            this.setDataLayer('all_content_click', 'all_recipe_click', '')
        },
        makeActive(event){
            let item = event.target
            let filterItems = document.getElementsByClassName("recipes__filter")[0].children

            filterItems.forEach(element => {
                element.classList.remove("active");    
            });

            console.log(item)

            item.classList.add("active");
        },
        needFirstborder(){
            let activeHash = this.$route.hash.replace("#",'')
            // let firstSlug = this.recipeCategories[0].name

            if (this.$route.hash == '') {
                return true
            }
            else if ( activeHash == this.recipeCategories[0].name ){
                return true
            } else {
                return false
            }
        },
        getFilterFromHash : function(){
            var filter_slug = this.$route.hash.replace("#",'');
            let _this = this
            // console.log('hash',filter_slug);

            if (filter_slug == "osszes"){
                this.current_category_filter = -1;                
            } else {
                
                for(var i in _this.recipeCategories){                   
                    if ( _this.url_slug(_this.recipeCategories[i].name) == filter_slug ){
                        _this.current_category_filter = _this.recipeCategories[i].id
                    }
                }
            }

            this.search()

        },        
        search(){
            let _this = this

            if ( parseInt(_this.current_category_filter) !== -1){
                //filtered recipes                
                _this.currentRecipeList = this.recipeList.filter( item => parseInt(item.recipe_category_id) === parseInt(_this.current_category_filter) )
            } else {                
                //all recipes
                _this.currentRecipeList = _this.recipeList
            }     
            
            //search by text
            var searchedList = []

            if (_this.search_text != ''){                

                _this.currentRecipeList.forEach(item => {
                    if (item.search_helper.indexOf(_this.search_text.toLowerCase()) > -1){
                        searchedList.push(item)
                    }
                })
                _this.currentRecipeList = searchedList         
                
                _this.setDataLayer('recipe_search', _this.search_text, '')
            }
        },
        resetFilter() {
            // let _this = this
            var clickedItem = event.target
            var clickedItemHash = clickedItem.getAttribute('href').split('#')[1]
            var urlHash = this.$route.hash.replace("#",'');            

            if (clickedItemHash == this.currentHash ){                
                document.getElementById('filter_0').click()
                this.setDataLayer('recipe_filter', 'inctive', clickedItemHash)
            } else {
                this.setDataLayer('recipe_filter', 'active', clickedItemHash)
            }

            this.currentHash = urlHash
        },      
    }
}
</script>